<template>
  <div class="page-header-index-wide">
    <a-card :bordered="false" :bodyStyle="{ padding: '16px 0', height: '100%' }" :style="{ height: '100%' }">
      <div class="account-settings-info-main">
        <div class="account-settings-info-left">
          <a-affix :offsetTop="68">
            <a-menu
              :mode="mode"
              :style="{ border: '0'}"
              :defaultSelectedKeys="defaultSelectedKeys"
              :selectedKeys="defaultSelectedKeys"
              type="inner"
              @openChange="onOpenChange"
            >
              <a-menu-item :key="item.path" v-for="item in menuItems">
                <router-link :to="{ path: item.path }">
                  {{item.meta.title}}
                </router-link>
              </a-menu-item>
            </a-menu>
          </a-affix>
        </div>
        <route-view></route-view>
      </div>
    </a-card>
  </div>
</template>

<script>

  import RouteView from '../../components/layouts/RouteView'
  import { findMenu } from '../../utils/util'
  import { mapState } from 'vuex'

  export default {
    components: {
      RouteView,
    },
    data () {
      return {
        // horizontal  inline
        mode: 'inline',
        openKeys: [],
        defaultSelectedKeys: [],
        pageTitle: '',
        menuItems: [],
        selectedMenu: { meta: {} }
      }
    },
    created () {
      this.init()
    },
    watch: {
      '$route.path' () {
        this.init()
      }
    },
    computed: {
      ...mapState({
        // 主路由
        mainMenu: state => state.user.columnItems
      })
    },
    methods: {
      init () {
        this.updateMenu()
        this.menuItems = this.leftMenu()
        this.selectedMenu = findMenu(this.mainMenu, this.defaultSelectedKeys[0], 3)
      },
      leftMenu () {
        const menu = findMenu(this.mainMenu, this.defaultSelectedKeys[0], 3)
        if (menu) {
          return menu.parent.children || []
        } else {
          return []
        }
      },
      onOpenChange (openKeys) {
        this.openKeys = openKeys
      },
      updateMenu () {
        const routes = this.$route.matched.concat()
        this.defaultSelectedKeys = [routes.pop().path]
      }
    }
  }
</script>